import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';

const Username = ({ field, form }) => {
  return (
    <FormControl isRequired isInvalid={form.errors.username && form.touched.username}>
      <FormLabel htmlFor="username">Username</FormLabel>
      <Input {...field} id="username" type="text" placeholder="Username" />
    </FormControl>
  );
};

const Title = ({ field, form }) => {
  return (
    <FormControl isRequired isInvalid={form.errors.title && form.touched.title}>
      <FormLabel htmlFor="title">Title</FormLabel>
      <Input {...field} id="title" type="text" placeholder="Title" />
    </FormControl>
  );
};

const Body = ({ field, form }) => {
  return (
    <FormControl isRequired isInvalid={form.errors.text && form.touched.text}>
      <FormLabel htmlFor="text">Body</FormLabel>
      <Textarea {...field} placeholder="Be a part of the conversation!" />
    </FormControl>
  );
};

export { Username, Title, Body }