import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
} from '@chakra-ui/react';

const Post = (props) => {
    const [post, setPost] = useState(props.post ? props.post : {});

    useEffect(() => {
        const getPost = async () => {
            const response = await fetch(
                `https://serverless-api.beelzebub.workers.dev/api/posts/${props.key}`
            );
            const json = await response.json();
            setPost(json);
        };

        if (!props.post) getPost();
    }, [props]);

    if (!Object.keys(post).length) return <Box p="6" />;

    return (
        <Box maxW="md" shadow="md" borderWidth="1px" borderRadius="md" p="4" >
            <Box
                display="flex"
                color="teal"
                fontWeight="bold"
                fontSize="xs"
                textTransform="uppercase"
                ml="3"
            >
                {post.username}
            </Box>

            <Box
                mt="1"
                fontWeight="semibold"
            >
                {post.title}
            </Box>

            <Box textAlign="justify">
                {post.text}
            </Box>

            <Box display="flex" mt="2" alignItems="center">
                <Button colorScheme="teal" variant="outline" size="xs">
                    {post.comments.length} comments
                </Button>
            </Box>
        </Box>
    );
};

export default Post;