import React, { useEffect, useState } from "react";
import {
    ChakraProvider,
    VStack,
    Divider,
    Flex,
    theme,
} from '@chakra-ui/react';
import Post from "./post"
import PostForm from "./postForm"

const Posts = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            const response = await fetch(
                "https://serverless-api.beelzebub.workers.dev/api/posts"
            );
            const json = await response.json();
            const sortedPosts = json.sort((a, b) => new Date(b.published_at) - new Date(a.published_at))
            setPosts(sortedPosts);
        };

        getPosts();
    }, []);

    return (
        <ChakraProvider theme={theme}>
            <VStack
                margin={4}
                spacing={4}
                align="center"
            >
                {posts.map((post) => {
                    return <Post key={post.username + post.published_at} post={post} />
                })}
            </VStack>
            <Flex justifyContent alignContent>
                <Divider align="center" m="10" />
            </Flex>
            <PostForm posts={posts} setPosts={setPosts}></PostForm>
        </ChakraProvider>
    );
};

export default Posts;