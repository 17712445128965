import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { ColorModeScript } from "@chakra-ui/react"
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <StrictMode>
    <ColorModeScript />
    <App />
  </StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
