import React from "react";
import {
  Box,
  Container,
  Button,
  Flex,
} from '@chakra-ui/react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Username, Title, Body } from './fields'
const PostForm = ({ setPosts }) => {
  return (
    <Formik
      initialValues={{ username: '', title: '', text: '' }}
      validationSchema={Yup.object({
        username: Yup.string()
          .required('Required'),
        title: Yup.string().required()
          .max(50, 'Must be 20 characters or less')
          .required('Required'),
        text: Yup.string()
          .max(280, 'Must be 280 characters or less.')
          .required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        await fetch(`https://serverless-api.beelzebub.workers.dev/api/posts`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        })
          .then(response => {
            if (response.ok) {
              console.log("POST successful, status: " + response.status);
            } else {
              throw new Error("POST failed, status: " + response.status);
            }

            return response.json()
          })
          .then(post => {
            setPosts((posts) => [post, ...posts])
            console.log(post)
          })
          .catch(error => {
            console.error('Error: ', error);
          });

        setSubmitting(false)
      }}
    >
      {({ isSubmitting }) => (
        <Container maxW="md">
          <Form>
            <Flex justify="center" alignContent="center" flexDirection="column">
              <Box align="left" m="1">
                <Field name="username" component={Username} />
                <ErrorMessage name="username" />
              </Box>

              <Box m="1">
                <Field name="title" component={Title} />
                <ErrorMessage name="title" />
              </Box>

              <Box m="1">
                <Field name="text" component={Body} />
                <ErrorMessage name="text" />
              </Box>

              <Button
                mt={4}
                colorScheme="teal"
                isLoading={isSubmitting}
                type="submit"
                align="center"
              >
                Post
              </Button>
            </Flex>
          </Form>
        </Container>

      )
      }

    </Formik >
  )
}

export default PostForm;